import { SelectionChangedEvent } from "ag-grid-community";
import { TariffComponentValueEntity } from "domain/entity/TariffCode/TariffComponentValueEntity";
import { INITIAL_TARIFF_COMPONENT_VALUE_COL_DEF } from "presentation/constant/TariffComponent/TariffComponentDetailMaintenanceColumnDefinition";
import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const TariffComponentDetailTablePanel:React.FC = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate} = anainfoState;
    const { masterState, detailState} = tariffComponentState;
    const {isEditable,isRead} = masterState;
    const {detailList, isAdd: isDetailAdd, isEditable: isDetailEdit} = detailState;
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    // const messageBarVM = useMessageBarVM();   

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        tariffComponentVM.updateSelectedDetailRows(selectedRows);
    }, [tariffComponentVM])

    const handleDetailAddClick = useCallback(() => {
        tariffComponentVM.onDetailAdd();
    }, [tariffComponentVM])

    const handleRowDoubleClick = useCallback((entity: TariffComponentValueEntity) => {
          tariffComponentVM.onDetailRowClick(entity);
    }, [tariffComponentVM])

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='tariff-omponent-detail-table'
            headerLabel={TariffComponentMaintenanceConstant.TARIFF_COMPONENT_VALUE}
            isNewColumnSetting={true}
            columns={INITIAL_TARIFF_COMPONENT_VALUE_COL_DEF}
            data={detailList??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={allowCreate && !(isEditable && isRead) && !isDetailAdd && !isDetailEdit}
            showUploadIcon={allowCreate && !(isEditable && isRead) && !isDetailAdd && !isDetailEdit}
            onAddClick={handleDetailAddClick}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: TariffComponentValueEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[detailList, allowCreate, isEditable, isRead, isDetailAdd, isDetailEdit, handleDetailAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(TariffComponentDetailTablePanel);
