import * as yup from 'yup';


export const commonTariffComponentValidation: { [x: string]: any; } = {
  tariffCompCode: yup.string().required("Missing"),
};

export const commonTariffComponentValueValidation: { [x: string]: any; } = {
  tariffCompValueCode: yup.string().required("Missing"),
};

export const createTariffComponentValidation: { [x: string]: any; } = {
  ...commonTariffComponentValidation,
};

export const createTariffComponentValueValidation: { [x: string]: any; } = {
  ...commonTariffComponentValueValidation,
};

export const createTariffComponentValidationSchema = yup.object().shape(createTariffComponentValidation);
export const createTariffComponentValueValidationSchema = yup.object().shape(createTariffComponentValueValidation);

