import { TariffComponentMaintenanceConstant } from "./TariffComponentMaintenanceConstant";

const SCREEN_CONSTANT = TariffComponentMaintenanceConstant;
export const INITIAL_TARIFF_COMPONENT_COL_DEF: any[] = [
        {
            headerName: SCREEN_CONSTANT.SEQ,
            field: "seq",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
            //pinned: "left",
            //checkboxSelection: true,
            //headerCheckboxSelection: true,
            
        },
        {
            headerName: SCREEN_CONSTANT.TARIFF_COMPONENT,
            field: "tariffCompCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            
        },
        {
            headerName: SCREEN_CONSTANT.TARIFF_COMPONENT_DESC,
            field: "tariffCompDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 250,
        },
        {
            headerName: SCREEN_CONSTANT.ACTIVE_IND,
            field: "activeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
        },
]
