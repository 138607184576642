import { SelectionChangedEvent } from "ag-grid-community";
import { TariffComponentEntity } from "domain/entity/TariffCode/TariffComponentEntity";
import { INITIAL_TARIFF_COMPONENT_COL_DEF } from "presentation/constant/TariffComponent/TariffComponentMaintenanceColumnDefinition";
import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const TariffComponentTablePanel:React.FC = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    const {tableData} = tariffComponentState;
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    // const messageBarVM = useMessageBarVM();   

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        tariffComponentVM.updateSelectedRows(selectedRows);
    }, [tariffComponentVM])

    const handleAddClick = useCallback(() => {
        tariffComponentVM.onAdd();
    }, [tariffComponentVM])

    const handleRowDoubleClick = useCallback((entity: TariffComponentEntity) => {
          tariffComponentVM.onRowDoubleClick(entity);
    }, [tariffComponentVM])

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='tariff-omponent-table'
            headerLabel={TariffComponentMaintenanceConstant.TITLE}
            isNewColumnSetting={true}
            columns={INITIAL_TARIFF_COMPONENT_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAddClick}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: TariffComponentEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(TariffComponentTablePanel);
