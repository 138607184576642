import { TariffComponentMaintenanceConstant } from "./TariffComponentMaintenanceConstant";

const SCREEN_CONSTANT = TariffComponentMaintenanceConstant;
export const INITIAL_TARIFF_COMPONENT_VALUE_COL_DEF: any[] = [
        {
            headerName: SCREEN_CONSTANT.TARIFF_COMPONENT_VALUE,
            field: "tariffCompValueCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 220,
            
        },
        {
            headerName: SCREEN_CONSTANT.TARIFF_COMPONENT_VALUE_DESC,
            field: "tariffCompValueDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 270,
        },
        {
            headerName: SCREEN_CONSTANT.ACTIVE_IND,
            field: "activeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
        },
]
