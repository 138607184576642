
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffComponentDetailMaintenance from "./Detail/TariffComponentDetailMaintenance";
import { TariffComponentHeaderFormPanel } from "./TariffComponentHeaderFormPanel";
import TariffComponentMaintenance from "./TariffComponentMaintenance";

export const TariffComponentMasterView: React.FC = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const { isAdd, isEditable,isSliderOpen} = tariffComponentState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <TariffComponentMaintenance/>
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"30%"}
                    rightSectionWidth={"70%"}
                    leftChildren={<TariffComponentHeaderFormPanel/>}
                    rightChildren={<TariffComponentDetailMaintenance/>}
                />
                )
            }
        </div>
    </>
}