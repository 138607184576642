import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { memo, useEffect } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffComponentDetailFormPanel } from "./TariffComponentDetailFormPanel";
import TariffComponentDetailTablePanel from "./TariffComponentDetailTablePanel";

const TariffComponentDetailMaintenance: React.FC = () => {
        const [tariffComponentState] = useTariffComponentMaintenanceTracked();
        const tariffComponentVM = useTariffComponentMaintenanceVM();
        const { isSliderOpen, } = tariffComponentState.detailState
        const currTariffComponentEntity = tariffComponentState.currentSelectedRow;
        useEffect(() => {
                currTariffComponentEntity?.id && tariffComponentVM.onSearchDetail(currTariffComponentEntity.id)
            }, [currTariffComponentEntity.id, tariffComponentVM])

        return <div className='main-comp-wrapper'>
                <SliderPanel
                        isOpen={isSliderOpen}
                        leftSectionWidth={'100%'}
                        rightSectionWidth={'60%'}
                        draggable={true}
                        leftChildren={<TariffComponentDetailTablePanel />}
                        rightChildren={<TariffComponentDetailFormPanel />} />
        </div>
}

export default memo(TariffComponentDetailMaintenance);