import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TariffComponentDetailTitleBar from "./Detail/TariffComponentDetailTitleBar";

export const TariffComponentHeaderFormPanel = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const {currentSelectedRow, masterState} = tariffComponentState;
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState;
    const SCREEN_CONSTANT = TariffComponentMaintenanceConstant;
    

    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const memoTarCompCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCompCode || ''}
                fieldValue={currentEntity?.tariffCompCode}
                fieldLabel={SCREEN_CONSTANT.TARIFF_COMPONENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCompCode'}
                isShowMissingError={true}
                maxLength={35}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffComponentVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, isAdd, currentEntity?.tariffCompCode, SCREEN_CONSTANT.TARIFF_COMPONENT, isSaveClicked, tariffComponentVM])

        const memoDesc = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffCompDesc || ''}
                    fieldValue={currentEntity?.tariffCompDesc}
                    fieldLabel={SCREEN_CONSTANT.TARIFF_COMPONENT_DESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffCompDesc'}
                    isShowMissingError={true}
                    maxLength={35}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffComponentVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [allFormState, isRead, currentEntity?.tariffCompDesc, SCREEN_CONSTANT.TARIFF_COMPONENT_DESC, isSaveClicked, tariffComponentVM])

        const memoActiveInd = useMemo(() =>
            <>
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
    
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd==='Y'}
                    disabled={isRead}
                    onChange={(e) => tariffComponentVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
            </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, tariffComponentVM])

        
    const memoEditPanelTitleBar = useMemo(() => {
        return <TariffComponentDetailTitleBar/>
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>{!!currentEntity?.tariffCompCode?currentEntity.tariffCompCode : "NEW CODE"}</SidebarTitle>

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        
                        <CriteriaItemContainer>
                            {memoTarCompCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoDesc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
